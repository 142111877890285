import { useParams } from "react-router";

interface CustomParams extends Record<string, string | undefined> {
  contractId: string;
  customerId: string;
  locationId: string;
  customerItemId: string;
  masterRouteId: string;
  routeTemplateId: string;
  routeId: string;
}

/**
 * Overrides the useParams from React router to avoid undefined
 */
export const useCustomParams = (): CustomParams => {
  const { contractId, customerId, customerItemId, masterRouteId, routeTemplateId, routeId, locationId } =
    useParams<CustomParams>();

  return {
    contractId: contractId || "",
    customerId: customerId || "",
    locationId: locationId || "",
    customerItemId: customerItemId || "",
    masterRouteId: masterRouteId || "",
    routeTemplateId: routeTemplateId || "",
    routeId: routeId || "",
  };
};

export default useCustomParams;
