import { Markerable, Marker } from "@@types/markers/locationMarker";
import { LiftingMapMarker } from "@components/location/context/LocationContext";

const MapLocationLiftingMarkerToMapMarker = (liftingMapMarker: LiftingMapMarker): Markerable => {
  return {
    _sortingLatitude: parseFloat(liftingMapMarker.lat),
    _sortingLongitude: parseFloat(liftingMapMarker.long),
    marker: {
      id: liftingMapMarker.id,
      position: {
        lat: parseFloat(liftingMapMarker.lat),
        lng: parseFloat(liftingMapMarker.long),
      },
      time: liftingMapMarker.dateTime,
      type: liftingMapMarker.manual ? "manualLifting" : "automaticLifting",
    },
  };
};

const MapLocationLiftingMarkersToMapMarkers = (liftingMapMarkers: LiftingMapMarker[]): Markerable[] => {
  return liftingMapMarkers.map(MapLocationLiftingMarkerToMapMarker);
};

const MapMarkerToLiftingMapMarker = (marker: Marker): LiftingMapMarker => {
  return <LiftingMapMarker>{
    id: marker.id,
    lat: marker.position.lat.toString(),
    long: marker.position.lng.toString(),
    dateTime: marker.time,
    manual: marker.type === "manualLifting",
  };
};

const MapMarkersToLiftingMapMarkers = (markers: Marker[]): LiftingMapMarker[] => {
  return markers.map(MapMarkerToLiftingMapMarker);
};
export { MapLocationLiftingMarkerToMapMarker, MapLocationLiftingMarkersToMapMarkers, MapMarkersToLiftingMapMarkers };
