import React, { createContext, Dispatch, ReactElement, SetStateAction, useCallback, useMemo, useState } from "react";
import useCustomParams from "@hooks/useCustomParams";
import useFilterAndPagination from "@hooks/useFilterAndPagination";
import { TableFilterStates, UpdateFilterStates } from "@@types/common/table-filter-states.type";
import { LiftingRequest } from "@@types/lifting-requests/lifting-request.type";
import { offsetMarkersOnSameSpot } from "@utils/offsetMarkers";
import { Markerable } from "@@types/markers/locationMarker";
import { sortBy } from "@components/subRouteManagement/utils/sortHashes";
import {
  MapLocationLiftingMarkersToMapMarkers,
  MapMarkersToLiftingMapMarkers,
} from "@@types/markers/mappers/mapLocationLiftingMarkersToMapMarkers.mapper";

export type LocationContextState = {
  showLiftingsOnMap: boolean;
  setShowLiftingsOnMap: Dispatch<SetStateAction<boolean>>;
  contractId: string;
  customerId: string;
  selectedContainers: number[];
  setSelectedContainers: Dispatch<SetStateAction<number[]>>;
  filterStates: TableFilterStates;
  updateFilterStates: UpdateFilterStates;
  liftingMapMarkers: LiftingMapMarker[] | undefined;
  setLocationLiftingMapMarkers: (liftingRequests: LiftingRequest[]) => void;
};

const INITIAL_FILTER_STATE = {
  sort: { columnName: "startDate", direction: "desc" },
  filter: "",
  rowsPerPage: 10,
  page: 0,
};

export type LiftingMapMarker = {
  id: number;
  lat: string;
  long: string;
  dateTime: string;
  manual: boolean;
};

export const LocationContext = createContext({} as LocationContextState);

type Props = {
  children: ReactElement;
};

function LocationProvider({ children }: Props): ReactElement {
  const { contractId, customerId } = useCustomParams();
  const [liftingMapMarkers, setLiftingMapMarkers] = useState<LiftingMapMarker[]>();
  const [showLiftingsOnMap, setShowLiftingsOnMap] = useState<boolean>(false);
  const [selectedContainers, setSelectedContainers] = useState<number[]>([]);
  const { filterStates, updateFilterStates } = useFilterAndPagination(INITIAL_FILTER_STATE);

  const setLocationLiftingMapMarkers = useCallback(
    (liftingRequests: LiftingRequest[]): void => {
      const data: LiftingMapMarker[] = liftingRequests.flatMap((l) => l.liftingLogs).filter((i) => i != null);

      liftingMapMarkers?.length != data.length &&
        setLiftingMapMarkers(
          MapMarkersToLiftingMapMarkers(
            sortBy<Markerable>(
              offsetMarkersOnSameSpot(MapLocationLiftingMarkersToMapMarkers(data), true),
              "_sortingLatitude"
            ).map((i) => i.marker)
          )
        );
    },
    [liftingMapMarkers?.length]
  );

  const value: LocationContextState = useMemo(
    () => ({
      showLiftingsOnMap,
      setShowLiftingsOnMap,
      setSelectedContainers,
      contractId,
      customerId,
      selectedContainers,
      filterStates,
      updateFilterStates,
      liftingMapMarkers,
      setLocationLiftingMapMarkers,
    }),
    [
      showLiftingsOnMap,
      contractId,
      customerId,
      selectedContainers,
      filterStates,
      updateFilterStates,
      liftingMapMarkers,
      setLocationLiftingMapMarkers,
    ]
  );

  return <LocationContext.Provider value={value}>{children}</LocationContext.Provider>;
}

function useLocationContext(): LocationContextState {
  const context = React.useContext(LocationContext);
  if (context === undefined) {
    throw new Error("useLocationContext must be used within a LocationProvider");
  }
  return context as unknown as LocationContextState;
}

/**
 * Children component
 * @param Component
 */
function withLocationContext(Component: React.ComponentType<unknown>): React.ComponentType<unknown> {
  return function fn() {
    return (
      <LocationProvider>
        <Component />
      </LocationProvider>
    );
  };
}

export { LocationProvider, withLocationContext, useLocationContext };
